import copy from 'copy-to-clipboard'
import React, {FC, useEffect, useState} from 'react'
import {useMedia} from '../hooks/useMedia'
import {Api} from '../net/api'
import {Keyword} from '../net/module/keyword'
import {notice} from '../utils/notification'
import {Icon} from './Icon'

interface Props {
}

let intervalId: NodeJS.Timer | undefined
let isUnmount = false
export const SearchList: FC<Props> = () => {
  const [searchRecords, setSearchRecords] = useState<Keyword[]>([])
  const [recordsPages, setRecordsPages] = useState(0)
  const [searchRecordsCurrent, setSearchRecordsCurrent] = useState(1)
  const [showRecords, setShowRecords] = useState(false)
  const {isMobile} = useMedia()
  const isDev = process.env.NODE_ENV === 'development'
  const getRecords = async () => {
    const {ret} = await Api.searchRecordsList({current: searchRecordsCurrent, size: 11})
    if (!isUnmount) {
      setSearchRecords(ret.records)
      setRecordsPages(ret.pages)
      // setSearchRecordsCurrent(ret.current)
    }
  }
  const refresh = () => {
    setSearchRecordsCurrent(1)
    getRecords()
    intervalId = setInterval(() => {
      getRecords()
    }, 5000)
  }
  useEffect(() => {
    refresh()
    isUnmount = false
    return () => {
      isUnmount = true
    }
  }, [])
  // useEffect(() => {
  //   getRecords()
  //   intervalId && clearInterval(intervalId)
  //   intervalId = setInterval(() => {
  //     getRecords()
  //   }, 5000)
  // }, [searchRecordsCurrent])
  return (
    <div className="main-right" style={{display: isMobile ? showRecords ? 'flex' : 'none' : 'flex'}}>
      <div className={'page-btn-wrap'}>
        {
          isMobile ?
            <Icon
              className={'refresh-records'}
              style={{left: 10}}
              name={'close'}
              size={18}
              onClick={() => setShowRecords(false)}
            /> :
            null
        }
        <Icon
          name={'left'}
          size={30}
          onClick={() => {
            intervalId && clearInterval(intervalId)
            setSearchRecordsCurrent(prev => Math.max(1, prev - 1))
            getRecords()
          }}
        />
        <span>{searchRecordsCurrent}</span>
        <Icon
          name={'right'}
          size={30}
          onClick={() => {
            intervalId && clearInterval(intervalId)
            setSearchRecordsCurrent(prev => Math.min(recordsPages, prev + 1))
            getRecords()
          }}
        />
        <Icon className={'refresh-records'} name={'refresh'} size={22} onClick={refresh}/>
      </div>
      <div className="record-wrap">
        {
          searchRecords.map(item => {
            return (
              <div
                key={item.id}
                className={'record-item-wrap'}
                onClick={() => {
                  const success = copy(item.keyword)
                  // success && notice(`「${item.keyword}」复制成功`, 'info')
                  // window.open('https://dapanso.com/search?keyword=' + item.keyword, 'blank')
                  // setSearchParams({keyword: item.keyword, status: ItemStatus.passed + '', page: '1', type: ''})
                  // window.location.reload()
                }}
              >
                <div className={'record-item'}>
                  <a className={'keyword'} href={`${isDev ? 'http://localhost:3000' : 'https://dapanso.com'}/search?keyword=${item.keyword}`} target={'_blank'}>
                    <span style={{color: '#888', fontSize: 14, marginRight: 2}}>[{item.searchTimes}]</span>
                    {item.keyword}
                  </a>
                  <span className={'time'}>{item.updatedAt.split(' ')[1]}</span>
                  {/*<div style={{flex: 1}}>*/}
                  {/*  /!*<span className={'st'}>{item.searchTimes}</span>*!/*/}
                  {/*  /!*<span className={'rt'}> / {item.relevantTotal}</span>*!/*/}
                  {/*</div>*/}
                </div>
                <div className={'find-wrap'}>
                  <a href={`https://cdn.yiso.fun/info?searchKey=${item.keyword}`} target={'_blank'}>易搜</a>
                  <a
                    href={`https://upyunso.com/search.html?page=1&keyword=${item.keyword}&page=1&s_type=2`}
                    target={'_blank'}
                  >UP搜</a>
                  <a
                    href={`https://jujuso.com/#/main/common?q=${item.keyword}&page=1&size=50`}
                    target={'_blank'}
                  >优聚搜</a>
                  <a
                    href={`https://www.nmme.cc/s/1/${item.keyword}`}
                    target={'_blank'}
                  >橘子</a>
                  <a
                    href={`https://www.cuppaso.com/search?type=2&keyword=${item.keyword}&searchType=0&page=1`}
                    target={'_blank'}
                  >咔帕</a>
                  <a
                    href={`https://www.niceso.fun/search/?q=${item.keyword}`}
                    target={'_blank'}
                  >奈斯</a>
                </div>
                {/*<Icon*/}
                {/*  color={'#999'}*/}
                {/*  name={'search'}*/}
                {/*  onClick={(e) => {*/}
                {/*    e.stopPropagation()*/}
                {/*    // setSearchParams({keyword: item.keyword, page: '1', type, status: ItemStatus.passed + ''});*/}
                {/*    // window.location.reload()*/}
                {/*    const success = copy(item.keyword)*/}
                {/*    success && notice(`「${item.keyword}」复制成功`, 'info')*/}
                {/*    window.open('https://yiso.fun/info?searchKey=' + item.keyword)*/}
                {/*    // window.open('https://upyunso.com/search.html?page=1&keyword=' + item.keyword + '&search_folder_or_file=0&is_search_folder_content=0&is_search_path_title=0&category=all&file_extension=all&search_model=1')*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

