import '../styles/Top.scss'
import {Header} from '../components/Header'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import React, {useCallback, useEffect, useState} from 'react'
import {TopModule} from '../net/module/topModule'
import {Api} from '../net/api'
import {notice} from '../utils/notification'
import {Pagination} from '../components/Pagination'
import {LoadingImg} from '../components/Loading'
import {Input} from '../components/Input'
import {Button} from '../components/Button'
import {ClickLabel} from '../components/ClickLabel'
import {loadData, saveData} from '../utils/utils'

export const TopKey = 'TopList'
export const Top = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [data, setData] = useState<TopModule[]>(loadData(TopKey) as TopModule[] || [])
  const [current, setCurrent] = useState(1)
  const [pages, setPages] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [addData, setAddData] = useState<TopModule[]>([])
  const [editData, setEditData] = useState<TopModule[]>([])
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    saveData(TopKey, data)
  }, [data])
  useEffect(() => {
    setShowEdit(!!editData.length)
  }, [editData])
  useEffect(() => {
    setShowAdd(!!addData.length)
  }, [addData])
  const addTop = async () => {
    const data = addData.filter(item => item.resources.id)
    if (data.length) {
      try {
        const _data = data.map(item => ({resourcesId: item.resources.id, orderNum: item.orderNum}))
        await Api.addTop({resourcesId: data[0].resources.id, orderNum: data[0].orderNum})
        setShowAdd(false)
        await getData()
      } catch (e) {
        notice(e + '', 'error')
      }
    } else {
      notice('无可用资源ID', 'error')
    }
  }
  const getData = async () => {
    try {
      !data.length && setLoading(true)
      const {ret: ret1} = await Api.getTopList({current, size: 20})
      if (ret1.records.length) {
        await Api.editOrder({id: ret1.records[0].id, orderNum: ret1.records[0].orderNum + 1})
      }
      const {ret} = await Api.getTopList({current, size: 15})
      setLoading(false)
      setPages(ret.pages)
      setData(ret.records.sort((a, b) => b.id - a.id))
      notice('更新成功', 'info')
    } catch (e) {
      setLoading(false)
      notice(e + '', 'error')
    }
  }
  const onDelete = async (data: TopModule) => {
    if (!window.confirm(`确定删除\n${data.resources.name}?`)) return
    try {
      setData(prevState => prevState.filter(item => item.resources.id !== data.resources.id))
      await Api.delTop(data.id)
    } catch (e) {
      setData(prevState => [...prevState, data].sort((first, second) => second.orderNum - first.orderNum))
      notice(e + '', 'error')
    }
  }
  const onEditOrder = async (data: TopModule, order: number) => {
    try {
      await Api.editOrder({id: data.id, orderNum: order})
    } catch (e) {
      notice(e + '', 'error')
    }
  }
  const onEdit = useCallback(async () => {
    try {
      setLoading(true)
      // const _data = editData.map(item => ({resourcesId: item.resources.id, orderNum: item.orderNum}))
      // console.log(_data); //order-id;order-id;
      await Api.editOrder({id: editData[0].id, orderNum: editData[0].orderNum})
      setLoading(false)
      await getData()
    } catch (e) {
      setLoading(false)
      notice(e + '', 'error')
    }
  }, [editData])
  return (
    <>
      <Header/>
      <div className={'top'}>
        <div className={'top-left'}>
          {
            showAdd ?
              <>
                <div style={{alignSelf: 'flex-end', display: 'flex', flexDirection: 'row'}}>
                  <Button
                    text={'取消'}
                    color={'#fff'}
                    onClick={() => window.confirm('确定取消添加？') && setShowAdd(false)}
                    style={{padding: 15, color: '#f53232', fontSize: 16}}
                  />
                  <Button
                    style={{padding: 15, color: addData.length > 9 ? '#4fae7b44' : '#4fae7b', fontSize: 16}}
                    text={'新增'}
                    color={'#fff'}
                    disabled={addData.length > 9}
                    onClick={() => {
                      setAddData(prevState => {
                        return [{orderNum: prevState[0].orderNum + 1, resources: {}} as TopModule, ...prevState]
                      })
                    }}
                  />
                  <Button
                    text={'确定'}
                    color={'#fff'}
                    onClick={addTop}
                    style={{padding: 15, color: '#6A90FF', fontSize: 16}}
                  />
                </div>
                {
                  addData.map((item, index) => {
                      return (
                        <TopItem
                          key={item.orderNum}
                          data={item}
                          onOrderNumChange={(value) => {
                            setAddData(data => {
                              let old = [...data]
                              old[index] = {...old[index], orderNum: value}
                              return old
                            })
                          }}
                          onResourceIdChange={(value) => {
                            setAddData(data => {
                              let old = [...data]
                              old[index] = {...old[index], resources: {...old[index].resources, id: value}}
                              return old
                            })
                          }}
                          onDelete={() => {
                            setAddData(data => {
                              let old = [...data]
                              old.splice(index, 1)
                              return old
                            })
                          }}
                        />
                      )
                    },
                  )
                }
              </> :
              <div style={{alignSelf: 'flex-end', display: 'flex', flexDirection: 'row'}}>
                <Button
                  text={'添加'}
                  color={'#fff'}
                  onClick={() => {
                    setAddData([{orderNum: data.length > 0 ? data[0].orderNum + 1 : 0, resources: {}} as TopModule])
                    setShowAdd(true)
                  }}
                  style={{padding: 15, color: '#6A90FF', fontSize: 16}}
                />
                {
                  showEdit ?
                    <Button
                      text={'确认修改'}
                      color={'#fff'}
                      onClick={onEdit}
                      style={{padding: 15, color: '#4fae7b', fontSize: 16}}
                    /> : null
                }
              </div>
          }
          {
            loading || data.length === 0 ?
              <div className={'page-loading-wrap'}>
                {
                  !loading ?
                    <span className={'no-tips'}>{'暂无数据'}</span> :
                    <LoadingImg/>
                }
              </div> :
              <>
                {
                  data.map(item => {
                    return (
                      <TopItem
                        key={item.resources.id}
                        data={item}
                        onDelete={onDelete}
                        onOrderNumChange={(value) => {
                          const data = {...item, orderNum: value}
                          setEditData(prevState => {
                            const index = prevState.findIndex(state => state.resourcesId === item.resourcesId)
                            if (index !== -1) {
                              return prevState.splice(index, 1, data)
                            } else {
                              return prevState.concat(data)
                            }
                          })
                        }}
                        onResourceIdChange={(value) => {
                          const data = {...item, resources: {...item.resources, id: value}}
                          setEditData(prevState => {
                            const index = prevState.findIndex(state => state.resourcesId === item.resourcesId)
                            if (index !== -1) {
                              let old = [...prevState]
                              old.splice(index, 1, data)
                              return old
                            } else {
                              return prevState.concat(data)
                            }
                          })
                        }}
                      />
                    )
                  })
                }
              </>
          }
          <Pagination
            pages={pages}
            current={current}
            onPageChange={setCurrent}
          />
        </div>
        <div className={'top-right'}/>
      </div>
    </>
  )
}

interface TopItemProps {
  data: TopModule | undefined;
  onDelete?: (data: TopModule) => void;
  onResourceIdChange: (value: number) => void;
  onOrderNumChange: (value: number) => void;
}

const TopItem = ({data, onDelete, onOrderNumChange, onResourceIdChange}: TopItemProps) => {
  if (!data) return null
  return (
    <div className={'top-item'}>
      <Input
        className={'order-num'}
        defaultValue={data.orderNum}
        onChange={(value) => onOrderNumChange(parseInt(value))}
      />
      <Input
        className={'resource-id'}
        placeholder={'资源ID'}
        defaultValue={data?.resources?.id}
        onChange={(value) => onResourceIdChange(parseInt(value))}
      />
      <Link
        to={'/detail/' + data?.resources?.id}
        state={data?.resources}
        className={'name'}
      >
        {data.resources.name}
      </Link>
      <ClickLabel text={data?.resources?.clickNumber}/>
      {
        onDelete ?
          <svg
            onClick={() => onDelete(data)}
            className="delete-icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="40"
          >
            <path
              fill={'#ec4141'}
              d="M504.4 217.2c-170.7 0-309 138.4-309 309s138.4 309 309 309 309-138.4 309-309-138.3-309-309-309zM689.9 563h-368c-19.9 0-36-16.1-36-36s16.1-36 36-36h368c19.9 0 36 16.1 36 36s-16.1 36-36 36z"
            />
          </svg> :
          null
      }
    </div>
  )
}
